import React, { useState, useEffect } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

import history from '../services/history';

import api from '../services/api';

function ThemeProvider({ children }) {
  const [theme, setTheme] = useState({
    background: {
      url: '',
    },
  });

  const unidade_id = history.location.pathname.split('/')[1] || null;

  useEffect(() => {
    async function getTheme() {
      if (!unidade_id) return;

      const response = await api.get(`pageinfo/unidade/${unidade_id}`);

      if (response.data) {
        setTheme(response.data);
      }
    }

    getTheme();
  }, [unidade_id]);

  return (
    <>
      <StyledComponentsThemeProvider theme={theme}>
        {children}
      </StyledComponentsThemeProvider>
    </>
  );
}

export default ThemeProvider;
