import React from 'react';

import { Link } from 'react-router-dom';

import currencyFormat from '../../services/currency';

import {
  Container,
  Name,
  Price,
  Recurrency,
  Charge,
  Installment,
  Trial,
  Features,
} from './styles';

export default function Plan({ plan, unidade_id, unidade_nome }) {
  Container.defaultProps = {
    color: 'red',
  };

  return (
    <Container color={plan.color}>
      <Name>{plan.name}</Name>
      <Price>{currencyFormat(plan.amount)}</Price>
      {plan.installments > 1 && (
        <Installment>Em {plan.installments}x no cartão</Installment>
      )}
      <Features>{plan.description}</Features>

      {plan.charges !== 1 && (
        <Recurrency>Renovado a cada {plan.days} dias</Recurrency>
      )}

      {(() => {
        if (plan.charges === null) return <Charge>Recorrente</Charge>;

        if (plan.charges === 1)
          return <Charge>Sem renovação automática</Charge>;

        if (plan.charges === 2) return <Charge>Renovado 1 vez</Charge>;

        if (plan.charges > 2)
          return <Charge>Renovado {plan.charges - 1} vezes</Charge>;

        return <></>;
      })()}

      {plan.trial_days > 0 && <Trial>{plan.trial_days} dias de teste</Trial>}
      <Link
        style={{ textDecoration: 'none', color: '#fff' }}
        to={{
          pathname: `/${unidade_id}/plan/${plan.id}`,
          state: { unidade_nome },
        }}
      >
        Assinar
      </Link>
    </Container>
  );
}
