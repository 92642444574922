import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;

  img {
    width: 145px;
  }

  p {
    color: #ccc;
    margin-left: -9px;
  }
`;
