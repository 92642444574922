import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 40px;
  padding: 20px;
  background: linear-gradient(-90deg, #7159c1, #ab59c1);
  border-radius: 8px;
  width: 600px;

  p {
    color: #fff;
    font-size: 18px;
    text-align: center;
    line-height: 24px;
  }

  p + p {
    margin-bottom: 20px;
  }
`;

export const Title = styled.h1`
  font-size: 36px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin: 20px;
`;
