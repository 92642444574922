import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Wrapper, Title } from './styles';

export default function DisabledPage() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Conta inativa</title>
      </Helmet>
      <Container>
        <Wrapper>
          <Title>Conta inativa</Title>
          <p>A conta do vendedor está inativa.</p>
        </Wrapper>
      </Container>
    </>
  );
}
