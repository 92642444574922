import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import { FiFacebook, FiInstagram, FiTwitter, FiLink } from 'react-icons/fi';

import Plan from '../../components/Plan';
import SocialMediaIcon from '../../components/SocialMediaIcon';

import api from '../../services/api';
import history from '../../services/history';

import {
  Container,
  Header,
  Title,
  Description,
  Image,
  Plans,
  SocialMedia,
} from './styles';

export default function Home() {
  const [plans, setPlans] = useState(null);
  const [name, setName] = useState('');
  const [logo, setLogo] = useState('');
  const [socialMedia, setSocialMedia] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [pageInfo, setPageInfo] = useState({
    title: '',
    description: '',
  });

  const { unidade_id } = useParams();

  useEffect(() => {
    async function getPlans() {
      const response = await api.get(`plans/unidade/${unidade_id}?order=sort`);

      if (response.data.ativo === 0) {
        history.push('/account/disabled');

        return;
      }

      if (!response.data.plans) {
        setIsEmpty(true);

        return;
      }

      response.data.plans = response.data.plans.filter(
        (plan) => !plan.disabled && !plan.privateLink
      );

      setName(response.data.nome);
      setLogo(response.data.logo);
      setPlans(response.data.plans);
    }

    async function getPageInfo() {
      const response = await api.get(`pageinfo/unidade/${unidade_id}`);

      if (!response.data) return;

      setPageInfo(response.data);
    }

    getPlans();
    getPageInfo();
  }, [isEmpty, unidade_id]);

  useEffect(() => {
    async function getSocialMedia() {
      const response = await api.get(`unidade/${unidade_id}/social-media`);

      if (!response.data) return;

      setSocialMedia(response.data.links);
    }

    getSocialMedia();
  }, [unidade_id]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {(pageInfo.title.length > 0 && pageInfo.title) || 'Planos'}
        </title>
      </Helmet>
      {isEmpty ? (
        <Container>
          <Header>
            <Title>Nenhum plano cadastrado</Title>
          </Header>
        </Container>
      ) : (
        <Container>
          <Header>
            {(plans && (
              <Image
                src={`https://wiki4fit.com.br/imagem.php?imagem=${logo}`}
              />
            )) || <Skeleton circle height={130} width={130} />}
            <Title style={pageInfo.style?.title}>
              {pageInfo.title.length > 0 && `${pageInfo.title}`}
              {!plans && <Skeleton height={43} width={520} />}
            </Title>
          </Header>

          {plans ? (
            <Plans>
              {plans.map((plan) => (
                <Plan
                  key={plan.id}
                  plan={plan}
                  unidade_id={unidade_id}
                  unidade_nome={name}
                />
              ))}
            </Plans>
          ) : (
            <Plans>
              <Skeleton count={3} height={258} width={321} />
            </Plans>
          )}

          <Description style={pageInfo.style?.description}>
            {pageInfo.description.length > 0 && `${pageInfo.description}`}
          </Description>

          {socialMedia.length ? (
            <h2 style={pageInfo.style?.socialMediaTitle}>
              {pageInfo.socialMediaTitle || 'Mídias Sociais'}
            </h2>
          ) : (
            ''
          )}

          <SocialMedia>
            {socialMedia.map((social) => {
              const icons = {
                FiFacebook,
                FiInstagram,
                FiTwitter,
                FiLink,
              };

              return (
                <SocialMediaIcon
                  key={social.title}
                  title={social.title}
                  url={
                    social.url.startsWith('http')
                      ? social.url
                      : `https://${social.url}`
                  }
                  icon={icons[social.icon]}
                  styles={pageInfo.style?.socialMedia || {}}
                  fill="#333"
                />
              );
            })}
          </SocialMedia>
        </Container>
      )}
    </div>
  );
}
