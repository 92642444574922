import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 40px;
  padding: 20px;
  background: linear-gradient(-90deg, #7159c1, #ab59c1);
  border-radius: 8px;
  width: 600px;
  text-align: center;

  h2 {
    font-size: 28px;
    margin-top: 20px;
    color: #fff;
  }

  p {
    color: #fff;
    font-size: 16px;
    margin-top: 20px;

    line-height: 24px;

    strong {
      color: #fff;
      display: block;
    }
  }
`;

export const Title = styled.h1`
  font-size: 36px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  margin: 20px;
`;

export const BoletoContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const ButtonLink = styled.a`
  outline: 0;
  cursor: pointer;
  color: #fff;
  background: #7b19e6;
  font-size: 16px;
  border: none;
  padding: 10px 15px;
  margin: 10px 0 0 0px;
  border-radius: 4px;
  font-weight: bold;
  transition: background 0.5s;

  &:hover {
    background: ${shade(0.2, '#7b19e6')};
  }
`;
