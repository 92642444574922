import React from 'react';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';

import { Container, Wrapper, Title, ButtonLink, BoletoContent } from './styles';

import orderConfirmedImg from '../../assets/images/order-confirmed.svg';

export default function BoletoPage({ location }) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Pagamento por boleto</title>
      </Helmet>
      <Container>
        <Wrapper>
          <Title>Pagamento por boleto</Title>
          <img src={orderConfirmedImg} width="250" alt="Assinatura concluída" />
          <h2>Seu boleto foi gerado</h2>
          <p>
            Realize o pagamento para concluir sua assinatura e receber os dados
            de acesso.
          </p>

          <BoletoContent>
            <ButtonLink
              target="_blank"
              rel="noopener noreferrer"
              href={location.state.boleto_url}
            >
              Acessar boleto
            </ButtonLink>

            <p>
              <strong>Código de barras</strong>
              {location && location.state.boleto_barcode}
            </p>
            <p>
              <strong>Vencimento</strong>
              {format(
                new Date(location.state.boleto_expiration_date),
                'dd/MM/yyyy'
              )}
            </p>

            <ButtonLink
              target="_blank"
              rel="noopener noreferrer"
              href={location.state.manage_url}
            >
              Gerenciar assinatura
            </ButtonLink>
          </BoletoContent>
        </Wrapper>
      </Container>
    </>
  );
}
