import React from 'react';

import { Container } from './styles';

export default function SocialMediaIcon({ title, icon: Icon, url, styles }) {
  return (
    <Container>
      {Icon && (
        <a target="_blank" rel="noopener noreferrer" href={url} alt={title}>
          <Icon size={styles?.fontSize || 30} color={styles?.color} />
        </a>
      )}
    </Container>
  );
}
