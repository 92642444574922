import axios from 'axios';

const api = axios.create({
  baseURL:
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? 'http://localhost:4444'
      : 'https://payments.wiki4fit.com.br',
});

export default api;
