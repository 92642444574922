import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

    @-webkit-keyframes autofill {
      0%,100% {
          color: #fff;
          background: rgba(0,0,0,0.1);
      }
  }

  @keyframes autofill {
      0%,100% {
          color: #fff;
          background: rgba(0,0,0,0.1);
      }
  }

  input:-webkit-autofill {
      -webkit-animation-delay: 1s;
      animation-delay: 1s;
      -webkit-animation-name: autofill;
      animation-name: autofill;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
  }

  html, body, #root {
    height: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased;
    background-color: #333;
    background: url(${(props) => props.theme.background.url}) no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }

  body, input, button {
    font: 14px 'Roboto', sans-serif;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  input:focus {
    border: 2px solid white!important;
    border-radius: 4px!important;
  }
`;
