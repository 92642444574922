import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 40px;
  padding: 20px;
  background: linear-gradient(-90deg, #7159c1, #ab59c1);
  border-radius: 8px;
  width: 600px;
  min-height: 500px;

  #credit-card-payment {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }
`;

export const Title = styled.h1`
  font-size: 36px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin: 10px;
`;

export const Installment = styled.p`
  font-size: 24px;
  color: #fff;
`;

export const Price = styled.h3`
  font-size: ${(props) => (props.size ? props.size : '24px')};
  font-weight: 400;
  color: #fff;
`;

export const Description = styled.p`
  margin: 20px 0;
  font-weight: bold;
  color: #fff;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;

  span > span {
    margin-bottom: 10px;
  }

  input,
  #credit-card-payment input {
    background: rgba(0, 0, 0, 0.1);
    border: 0;
    border-radius: 4px;
    height: 44px;
    padding: 0 15px;
    color: #fff;
    margin: 10px 0 0 0px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  a {
    color: #fff;
    margin-top: 15px;
    font-size: 16px;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  .acceptedBrands {
    color: #fff;
    margin: 10px 5px 0px;
    font-weight: bold;
  }

  p {
    color: #fb6f91;
    margin: 10px 0 5px 0;
    font-weight: bold;
  }
`;

export const Button = styled.button`
  outline: 0;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  border: none;
  padding: 10px 15px;
  margin: 10px 0 0 0px;
  border-radius: 4px;
  font-weight: bold;
  transition: background 0.5s;

  &:hover {
    background: ${(props) => shade(0.2, props.color)};
  }

  ${(props) =>
    props.color &&
    css`
      background: ${props.color};
      color: #fff;
    `}

  ${(props) =>
    props.active &&
    css`
      border: solid 2px #fff;
      padding: 8px 15px;
    `}
`;

export const Genre = styled.div`
  text-align: left;
  background: rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-top: 10px;
  border-radius: 4px;
  color: #fff;

  strong {
    display: block;
    color: #dac8e2;
    font-weight: 500;
  }

  input {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    vertical-align: bottom;
  }

  > label {
    margin-right: 15px;
  }
`;

export const Installments = styled.div`
  text-align: left;
  background: rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-top: 10px;
  border-radius: 4px;
  color: #fff;

  strong {
    display: block;
    color: #dac8e2;
    font-weight: 500;
  }
`;

export const PaymentMethod = styled.div`
  text-align: left;
  background: rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-top: 10px;
  border-radius: 4px;
  color: #fff;

  strong {
    display: block;
    color: #dac8e2;
    font-weight: 500;
  }

  button + button {
    margin-left: 10px;
  }
`;

Button.defaultProps = {
  color: '#7b19e6',
};
