import React from 'react';

import { Router, Route, Switch } from 'react-router-dom';
import history from './services/history';

import Home from './pages/Home';
import DisabledPage from './pages/Disabled';
import Subscribe from './pages/Subscribe';
import Success from './pages/Success';
import BoletoPage from './pages/BoletoPage';
import NotFound from './pages/NotFound';

import GlobalStyle from './styles/global';

import ThemeProvider from './theme/ThemeProvider';

export default function Routes() {
  return (
    <Router history={history}>
      <ThemeProvider>
        <Switch>
          <Route exact path="/:unidade_id" component={Home} />
          <Route exact path="/account/disabled" component={DisabledPage} />
          <Route
            exact
            path="/:unidade_id/plan/:plan_id"
            component={Subscribe}
          />
          <Route
            exact
            path="/:unidade_id/plan/:plan_id/success"
            component={Success}
          />
          <Route
            exact
            path="/:unidade_id/plan/:plan_id/boleto"
            component={BoletoPage}
          />
          <Route path="/" component={NotFound} />
        </Switch>

        <GlobalStyle />
      </ThemeProvider>
    </Router>
  );
}
