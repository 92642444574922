import React from 'react';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Checkout } from '../../components/Checkout';

export default function Subscribe() {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_PAYMENTS}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: 'head',
        nonce: undefined,
      }}
    >
      <Checkout />
    </GoogleReCaptchaProvider>
  );
}
