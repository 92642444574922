import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  padding-bottom: 20px;

  h2 {
    color: #fff;
    margin: 20px 0 10px 0;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 40px;
  max-width: 600px;
`;

export const Title = styled.h1`
  font-size: 36px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin: 10px;
`;

export const Description = styled.p`
  font-size: 24px;
  font-weight: 300;
  max-width: 600px;
  color: #fff;
  text-align: left;
  margin: 10px;
  white-space: pre-wrap;
`;

export const Image = styled.img`
  max-width: 250px;
`;

export const Plans = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1280px;
  width: 100%;

  @media (max-width: 1450px) {
    justify-content: center;
  }

  span {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  span > span {
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 40px;
    border-radius: 8px;
    text-align: center;
    max-width: 321px;
    min-width: 321px;
    min-height: 258px;
    display: flex;
    flex-direction: row;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  max-width: 250px;

  div + div {
    margin-left: 8px;
  }
`;
