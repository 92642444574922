import React from 'react';

import { Helmet } from 'react-helmet';

import { Container, Wrapper, Title } from './styles';

import notFoundImg from '../../assets/images/not-found.svg';

export default function NotFound() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Erro 404 - Página não encontrada</title>
      </Helmet>
      <Container>
        <Wrapper>
          <Title>Página não encontrada</Title>
          <p>Não encontramos a página que você tentou acessar.</p>
          <p>Verifique se a url está correta</p>
          <img src={notFoundImg} width="250" alt="Assinatura concluída" />
        </Wrapper>
      </Container>
    </>
  );
}
