import React from 'react';

import { Helmet } from 'react-helmet';

import { Container, Wrapper, Title } from './styles';

import orderConfirmedImg from '../../assets/images/order-confirmed.svg';

export default function Success({ location }) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Assinatura concluída</title>
      </Helmet>
      <Container>
        <Wrapper>
          <Title>Assinatura concluída</Title>
          <img src={orderConfirmedImg} width="250" alt="Assinatura concluída" />
          <h2>
            Seja bem-vindo {location && location.state && location.state.name}!
          </h2>
          <p>
            Você receberá seus dados de acesso no email cadastrado
            <span> {location && location.state && location.state.email}</span>
          </p>
        </Wrapper>
      </Container>
    </>
  );
}
