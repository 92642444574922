import styled from 'styled-components';

export const Container = styled.div`
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 40px;
  border-radius: 8px;
  text-align: center;
  max-width: 367px;
  min-width: 321px;
  min-height: 258px;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.color};

  a {
    outline: 0;
    cursor: pointer;
    background-color: #7b19e6;
    color: #fff;
    font-size: 16px;
    border: none;
    margin-top: 20px;
    padding: 10px 15px;
    border-radius: 4px;
    font-weight: bold;
    transition: background 0.5s;
    &:hover {
      background-color: #3e0082;
    }
  }
`;

export const Name = styled.h2`
  font-size: 36px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
`;

export const Price = styled.h3`
  font-size: 24px;
  font-weight: 400;
  color: #fff;
`;

export const Recurrency = styled.p`
  font-size: 16px;
  color: #ddd;
`;

export const Charge = styled.p`
  font-size: 16px;
  color: #ddd;
`;

export const Installment = styled.p`
  font-size: 18px;
  color: #fff;
`;

export const Trial = styled.p`
  margin-top: 5px;
  font-size: 16px;
  color: #0adb77;
`;

export const Features = styled.p`
  font-size: 16px;
  color: #fff;
  white-space: pre-line;
  margin: 10px;
`;
